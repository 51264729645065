html,body{
    width:100%;
    height:100%;
    padding:0;
    margin:0; 
    position:relative; 
    font-family:Arial, Helvetica, sans-serif;
    color:#999;
}

#root{
    width:100%;
    height:100%;
    position:relative;
}

#wrapper{
    height:100%;
    width:100%;
    background:#181818;
    color:#fff;
    display:flex;
    flex-direction:column;
    overflow:hidden;
}

.contactLink, .contactLink:hover, .contactLink:visited{
    color:#181818;
    text-decoration:none;
}



.whiteLink, .whiteLink:visited{
    color:#ffffff;
    text-decoration:underline;
}

.whiteLink:hover{
    color:#ffffff;
    text-decoration:none;
}


  /* width */
  ::-webkit-scrollbar {
    width: 13px;
    height:13px;
    overflow:hidden;
    
    /* border-bottom-right-radius:10px; */
    
}

/* Track */
::-webkit-scrollbar-track {
    background: #333;
    /* border-bottom-right-radius:10px; */
    overflow:hidden;
    
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #444;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor:pointer;
}

::-webkit-scrollbar-corner {background-color: #222;}

#titleBar{
    width:100%;
    height:5%;
    padding: 10px;
    display:flex;
    background-color: rgb(39,51,77);
}

#settingsButton{
    padding: 10;
    color: black;
}


#viewWrapper{
    background:rgba(0,0,0,0.03);
    position:relative;
    padding:0 3em 3em 3em;
    margin:0;
    overflow-y:auto;
    flex-grow:1;   
}

#viewWrapper .titleWrapper{
    padding: 3em 0;
    height: 1.3em;
}

#viewWrapper .titleWrapper .title{
    font-weight:bold;
    font-size:1.3em;
    color:#999;
}

#viewWrapper .content{
    position:relative;
    background:transparent;
    min-height:300px;
    -webkit-box-shadow:0px 0px 7px 0px rgba(0,0,0,0.05);
    box-shadow:0px 0px 7px 0px rgba(0,0,0,0.05);  
    width:100%;
    height:auto !important;
    display:flex;
    flex-direction:column;  
     
}

#viewWrapper .content .toolbar{
    width:calc(100% - 6em);
    height:3.5em;
    color:#999;
    padding:1em 0;

}




